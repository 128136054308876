import axiosSetup from "./axiosSetup"

const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://qa1-backend.buildbooster.com"

const { postRequest, getRequest } = axiosSetup(false, BASE_URL)

export const queryProducts = (requestBody) =>
    postRequest("/catalog/v3/searchProducts", requestBody)

export const getProductDetails = (requestBody) =>
    postRequest("/catalog/v3/getProduct", requestBody)
export const getProductCategories = (requestBody) =>
    postRequest("/catalog/v3/productCategory", requestBody)
export const getAllProducts = (requestBody) =>
    postRequest("/catalog/v3/getAllProducts", requestBody)

export const deleteProduct = (requestBody) =>
    postRequest("/catalog/v3/deleteProducts", requestBody)

export const printLabel = (requestBody) =>
    postRequest("/catalog/v3/pdf/barcodepdf", requestBody)

export const searchMasterProducts = (requestBody) =>
    postRequest("/catalog/v3/searchMasterProducts", requestBody)

export const exportAllProducts = (companyId) =>
    getRequest(
        `/catalog/v3/excel/download/tenantProducts?companyId=${companyId}&isTenant=true`
    )

export const exportAllStockTypeProducts = (companyId, stockType) =>
    getRequest(
        `/catalog/v3/excel/download/tenantProducts?companyId=${companyId}&stockType=${stockType}&isTenant=true`
    )

export const exportCatalog = (companyId, catalogId) =>
    getRequest(
        `/catalog/v3/excel/download/tenantProducts?companyId=${companyId}&catalogId=${catalogId}&isTenant=true`
    )

export const deleteCatalog = (requestBody) =>
    postRequest("/catalog/v3/deleteCatalog", requestBody)

export const adjustProductsMargin = (requestBody) =>
    postRequest("/catalog/v3/adjustMargin", requestBody)
// Vendor Products

export const getAllVendorProducts = (requestBody) =>
    postRequest("/catalog/v3/getVendorProductsMap", requestBody)

export const mapUnmapProducts = (requestBody) =>
    postRequest("/catalog/v3/mapVendorProducts", requestBody)

export const downloadExcelToMapVendorProduct = (requestBody) =>
    postRequest("/catalog/v3/excel/downloadVendorMapping", requestBody)

export const uploadVendorMapping = (requestBody) =>
    postRequest("/catalog/v3/excel/uploadVendorMapping", requestBody, {
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
    })

export const locationUpdateEditBill = (requestBody) =>
    postRequest("/catalog/v3/updateretailerproducts/vendor", requestBody)

export const connectTenantProducts = (requestBody) =>
    postRequest("/catalog/v3/connectTenantProducts", requestBody)

export const mapVendorProductsByBarcode = (requestBody) =>
    postRequest("/catalog/v3/mapVendorProductsBarcode", requestBody)

export const getCompanyCatalog = (companyId) =>
    getRequest(`/catalog/v3/getCompanyCatalogs?companyId=${companyId}`)

export const getVendorCatalogs = (requestBody) =>
    postRequest(`/catalog/v3/getVendorCatalogs`, requestBody)

export const downloadCompanyCatalog = (companyId, companyName, requestBody) =>
    postRequest(
        `catalog/v3/excel/downloadCompanyCatalog?companyId=${companyId}&companyName=${companyName}`,
        requestBody
    )

export const downloadLocalProductTemplate = (companyId, companyName) =>
    postRequest(
        `/catalog/v3/excel/downloadLocalProductAttributeCombinedCatalog?companyId=${companyId}&companyName=${companyName}`
    )

export const getCompanyCatalogs = (companyId, masterCompany = true) =>
    getRequest(
        `/catalog/v3/getCompanyCatalogs?companyId=${companyId}&masterCompany=${masterCompany}`
    )

export const uploadCatalogs = (requestBody) =>
    postRequest(`/catalog/v3/excel/uploadCatalog`, requestBody, {
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
    })

// Categrories

export const getAllCategories = (requestBody) =>
    postRequest("/catalog/v3/productCategory?needNoCategory=true", requestBody)

export const saveProductCategory = (requestBody) =>
    postRequest("/catalog/v3/saveProductCategory", requestBody)

export const createLocalProduct = (requestBody) =>
    postRequest("/catalog/v3/tenantProduct", requestBody)

export const getUOMProductList = (uom = "MBFT") =>
    getRequest(`/catalog/uomproducts?uom=${uom}`)

export const bulkUpdateCategory = (requestBody) =>
    postRequest("/catalog/v3/bulkupdatecategory", requestBody)

export const saveInventoryProductSettings = (requestBody) =>
    postRequest("/catalog/saveDefaultProductSettings", requestBody)

export const getInventoryProductSettings = (companyId) =>
    postRequest(`/catalog/getDefaultProductSettings?companyId=${companyId}`)

export const getBarcodeSizeList = () => getRequest("/catalog/barcodesize")

export const setPriceLevel = (requestBody) =>
    postRequest("catalog/v2/setPriceLevel", requestBody)

export const getPriceLevels = (companyId) =>
    getRequest(`/catalog/v2/getPriceLevel?companyId=${companyId}`)

export const getMasterUom = () => getRequest("/catalog/v3/getMasterUOM")

export const updateTenantProduct = (requestBody) =>
    postRequest("/catalog/v3/updateTenantProduct", requestBody)

export const getAllMasterProducts = (requestBody) =>
    postRequest("/catalog/v3/getAllMasterProducts", requestBody)

export const getVendorDetails = (requestBody) =>
    postRequest("/search/catalog/getVendorDetails", requestBody)
export const getMasterUOM = (requestBody) =>
    getRequest("catalog/v3/getMasterUOM", requestBody)

export const getProductTransferList = (requestBody) =>
    postRequest("catalog/v3/getInventoryTransfer", requestBody)
export const createProductTransfer = (requestBody) =>
    postRequest("catalog/v3/inventoryTransfer", requestBody)
export const editProductTransfer = (requestBody) =>
    postRequest("catalog/v3/update/inventoryTransfer", requestBody)
export const getProductTransferDetails = (
    parentCompanyId,
    inventoryTransferId
) =>
    getRequest(
        `catalog/v3/getInventoryTransferDetails?parentCompanyId=${parentCompanyId}&inventoryTransferId=${inventoryTransferId}`
    )

export const downloadSingleUpdateProducts = (requestBody) => {
    return postRequest(
        "/catalog/v3/excel/downloadSingleUpdateProducts",
        requestBody
    )
}

export const downloadProductSettingTemplate = (requestBody) => {
    return postRequest(
        "/catalog/v3/excel/downloadProductSettingTemplate",
        requestBody
    )
}

export const uploadUpdateProducts = (requestBody) =>
    postRequest("/catalog/uploadUpdateProducts", requestBody, {
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
    })

export const uploadSingleUpdateProducts = (requestBody) =>
    postRequest("/catalog/v3/excel/uploadSingleUpdateProducts", requestBody, {
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
    })

export const updateProductSetting = (requestBody) =>
    postRequest("/catalog/v3/excel/updateProductSetting", requestBody, {
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
    })
export const bulkProductUpdate = (requestBody) =>
    postRequest("/catalog/v3/bulk/product/update", requestBody)

export const getCmtdInventoryReport = (requestBody) =>
    postRequest("/catalog/v3/getCmtdInventoryReport", requestBody)
export const getCmtdInventoryForProducts = (requestBody) =>
    postRequest("/catalog/v3/getCmtdInventoryForProducts", requestBody)
export const getOrderQtyInventoryReport = (requestBody) =>
    postRequest("/catalog/v3/getOrderQtyInventoryReport", requestBody)
export const getOrderQtyInventoryForProducts = (requestBody) =>
    postRequest("/catalog/v3/getOrderQtyInventoryForProducts", requestBody)
export const getInventoryValuationReport = (requestBody) =>
    postRequest("/catalog/v3/getInventoryValuationReport", requestBody)
export const getInventoryValuationForProducts = (requestBody) =>
    postRequest("/catalog/v3/getInventoryValuationForProducts", requestBody)

export const getProductUpdateFields = () =>
    getRequest("/catalog/v3/getProductUpdateFields")

export const downloadTenantSpecificPrices = (
    requestBody,
    tenantId,
    vendorId,
    productType
) =>
    postRequest(
        `catalog/v3/excel/downloadtenantspecificprices?stockType=${productType}`,
        requestBody,
        {
            customHeaders: {
                vendorId: vendorId,
                tenantId: tenantId,
            },
        }
    )

export const downloadUpdatePriceTemplate = (
    requestBody,
    tenantId,
    vendorId,
    type
) =>
    postRequest(
        `catalog/v3/excel/downloadUpdatePriceTemplate?type=${type}`,
        requestBody,
        {
            customHeaders: {
                vendorId: vendorId,
                tenantId: tenantId,
            },
        }
    )

export const uplodTenantSpecificPrices = (requestBody, tenantId) =>
    postRequest("/catalog/v3/excel/uploadtenantspecificprices", requestBody, {
        customHeaders: {
            tenantId: tenantId,
            "Content-Type": "multipart/form-data",
        },
    })

export const updateCurrentMarketPrice = (requestBody, tenantId) =>
    postRequest("/catalog/v3/excel/updateCurrentMarketPrice", requestBody, {
        customHeaders: {
            tenantId: tenantId,
            "Content-Type": "multipart/form-data",
        },
    })

export const uploadUpdatePriceTemplate = (requestBody, tenantId) =>
    postRequest("/catalog/v3/excel/uploadUpdatePriceTemplate", requestBody, {
        customHeaders: {
            tenantId: tenantId,
            "Content-Type": "multipart/form-data",
        },
    })

export const downloadVendorMappingTemplate = (
    requestBody,
    tenantId,
    vendorId
) =>
    postRequest(
        "/catalog/v3/excel/downloadVendorMappingTemplate",
        requestBody,
        {
            customHeaders: {
                tenantId: tenantId,
                vendorId: vendorId,
            },
        }
    )

export const uploadVendorMappingTemplate = (requestBody, tenantId) =>
    postRequest("/catalog/v3/excel/uploadVendorMappingTemplate", requestBody, {
        customHeaders: {
            tenantId: tenantId,
            "Content-Type": "multipart/form-data",
        },
    })

export const getVendorProductByLocalProductSKU = (requestBody) =>
    postRequest("/catalog/vendormapproducts", requestBody)

export const getMappedRetailerProductsByVendorSku = (requestBody) =>
    postRequest("/catalog/v3/retailermapping/get", requestBody)
