import React, { useEffect, useMemo, useState } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Typography from "@mui/material/Typography"
import { getVendors } from "../../utils/company"
import { TextHighlighter } from "../../utils/utils"

const ANGULAR_URL =
    process.env.REACT_APP_ANGULAR_URL || "https://qa1-angular.buildbooster.com"

export const ALL_VENDOR = {
    compName: "All",
    compId: -1,
}
export const ADD_NEW_VENDOR = {
    compName: "Add New Vendor",
    compId: -1,
}
export default function VendorSelector({
    companyId,
    setSelectedVendor,
    selectedVendor = {},
    isSetDefault = true,
    showAll = false,
    selectionChanged,
    showAddNewVendor,
    filterSettings,
    includeInValidation = true,
    maxWidth = 600,
    minWidth = 160,
}) {
    const [inputValue, setInputValue] = useState(selectedVendor?.compName ?? "")
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const fetch = useMemo(
        () => (request, callback) => {
            setLoading(true)
            getVendors(request)
                .then((resp) => {
                    callback(resp.body.data)
                    if (isSetDefault) {
                        setSelectedVendor(resp.body.data[0])
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.error("Error: ", error)
                    setLoading(false)
                })
        },
        [setSelectedVendor, isSetDefault]
    )

    useEffect(() => {
        let requestBody = {
            companyId,
            status: "active",
            isOptimized: true,
        }

        fetch(requestBody, (results) => {
            if (filterSettings && !filterSettings?.onlyPlatformVendor) {
                results = results.filter((option) => !option?.isPlatformVendor)
            } else if (filterSettings && filterSettings?.onlyPlatformVendor) {
                results = results.filter((option) => option?.isPlatformVendor)
            }
            if (showAddNewVendor) {
                results?.unshift(ADD_NEW_VENDOR)
            }
            if (showAll && results?.length > 1 + (showAddNewVendor ? 1 : 0)) {
                results?.unshift(ALL_VENDOR)
                setSelectedVendor(ALL_VENDOR)
            }

            setOptions(results)
            // setSelectedVendor(results[0])
        })
    }, [companyId, fetch, showAll, setSelectedVendor, showAddNewVendor])

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)
        // if (selectionChanged) selectionChanged()
    }

    return (
        <Autocomplete
            sx={{ minWidth: { md: "24ch", lg: minWidth } }}
            maxWidth={maxWidth}
            id={showAddNewVendor ? "vendor-select-box" : ""}
            className="input-full-width"
            size="small"
            loading={loading}
            loadingText="Loading..."
            options={options}
            getOptionLabel={(option) =>
                option.compId === ALL_VENDOR.compId
                    ? option.compName
                    : (option.isPlatformVendor ? "P " : "L ") + option.compName
            }
            onChange={(event, newValue) => {
                if (newValue === ADD_NEW_VENDOR) {
                    window.open(`${ANGULAR_URL}/BB/purchases/vendors`)
                    setInputValue("")
                } else {
                    setSelectedVendor(newValue)
                    if (selectionChanged) selectionChanged()
                }
            }}
            isOptionEqualToValue={(option, value) =>
                option.compId === value.compId
            }
            clearOnBlur={false}
            value={selectedVendor}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            noOptionsText="No results!"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Vendor"
                    fullWidth
                    error={
                        includeInValidation &&
                        !selectedVendor?.compId &&
                        selectedVendor?.compId !== 0
                    }
                />
            )}
            renderOption={(props, { compName, compId, isPlatformVendor }) => (
                <li {...props} key={compName}>
                    <Typography>
                        {compId === ALL_VENDOR.compId
                            ? ""
                            : isPlatformVendor
                            ? "P "
                            : "L "}
                        <TextHighlighter
                            text={compName}
                            highlight={inputValue}
                        />
                    </Typography>
                </li>
            )}
        />
    )
}
