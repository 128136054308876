// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const ServicesFiltersContext = createContext()

// Create the provider component
export default function ServicesFiltersProvider({ children }) {
    const [selectedVendor, setSelectedVendor] = React.useState()
    const [selectedReceiveStatuses, setSelectedReceiveStatuses] =
        React.useState([])
    const [selectedPayoutStatuses, setSelectedPayoutStatuses] = React.useState(
        []
    )
    const [selectedSalespersons, setSelectedSalespersons] = React.useState([])
    const [fromDate, setFromDate] = React.useState(null)
    const [toDate, setToDate] = React.useState(null)
    const [fromAmount, setFromAmount] = React.useState()
    const [toAmount, setToAmount] = React.useState()

    const value = {
        selectedVendor,
        setSelectedVendor,
        selectedReceiveStatuses,
        setSelectedReceiveStatuses,
        selectedPayoutStatuses,
        setSelectedPayoutStatuses,
        selectedSalespersons,
        setSelectedSalespersons,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        fromAmount,
        setFromAmount,
        toAmount,
        setToAmount,
    }

    return (
        <ServicesFiltersContext.Provider value={value}>
            {children}
        </ServicesFiltersContext.Provider>
    )
}
