import { Chip, Tooltip, Typography } from "@mui/material"

import { Delete, Edit, Print } from "@mui/icons-material"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import locale from "date-fns/locale/en-US"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import React from "react"
import NumberFormatterText from "../../../components/NumberFormatterText"
import { RenderLink } from "../../purchase/receive-material-list/Utils"
import InsertLinkIcon from "@mui/icons-material/InsertLink"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import "../../styles/list-styles.scss"
export const dateAdapter = new AdapterDateFns({ locale })

export function statusRender({ value, row }) {
    value = row?.inventory?.thresholdStatus
    let color = "primary"
    let label = "-"
    if (value === 1) {
        color = "success"
        label = "In Stock"
    }
    if (value === 2) {
        color = "primary"
        label = "Reorder"
    }
    if (value === 3) {
        color = "primary"
        label = "Must Reorder"
    }
    if (value === 4) {
        color = "error"
        label = "Out of Stock"
    }
    return (
        <Typography color={color} label={label} className={color}>
            {label}
        </Typography>
    )
}

export function currencyRender({ value, decimalScale }) {
    return <NumberFormatterText value={value} decimalScale={decimalScale} />
}

export function preferredVendor(vendors, vendorList) {
    // console.log("preferredVendor.........", vendors, vendorList)
    const pv = vendors?.find((v) => v?.isPreferredVendor)
    return (
        <RenderLink
            value={pv ? getVendorNameById(vendorList, pv.vendorId) : ""}
            url={`/vendor?vendorId=${pv?.vendorId}`}
        />
    )
}

function getVendorNameById(vendorList, vId) {
    let obj = vendorList?.find((v) => v.compId === vId)
    return obj ? obj.compName : ""
}

export function mappedVendor(vendors, vendorList) {
    return (
        <>
            {vendors?.map((v, index) => (
                <Chip
                    key={`mapped-vendor-${index}`}
                    label={getVendorNameById(vendorList, v.vendorId)}
                    size="small"
                />
            ))}
        </>
    )
}

export function booleanRenderer({ value }) {
    return value ? "Yes" : "No"
}

export function mappedVendors({ value, row }) {
    return (
        <>
            {row?.vendorList?.map(
                (v) =>
                    !v?.preferredVendor && (
                        <RenderLink
                            key={`stocked-product-mapped-vendor-${v?.vendorId}`}
                            value={v?.vendorName}
                            url={`/vendor?vendorId=${v?.vendorId}`}
                        />
                    )
            )}
        </>
    )
}

export function catalogName(catalogId, catalogList) {
    let obj = catalogList.find((c) => c.catalogId === catalogId)
    return obj ? obj.catalogName : ""
}

export const GetActions = ({
    toggleDisconnectModal,
    setLoading,
    toggleDeleteModal,
    toggleConnectModal,
    togglePrintModal,
    ...params
}) => {
    const id = params.row.id
    const isConnected = params.row.msku
    const sku = params.row.sku

    let navigate = useNavigate()

    return [
        <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={(params) =>
                navigate(
                    `/inventory/product-details?sku=${sku}&edit=true&type=PRODUCT`
                )
            }
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => toggleDeleteModal([id])}
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Print />}
            label="Print"
            onClick={() => togglePrintModal([id], true)}
            color="#9A9A9A"
        />,
        isConnected ? (
            <Tooltip title="Disconnect">
                <GridActionsCellItem
                    icon={<LinkOffIcon />}
                    label="Disconnect"
                    onClick={() => toggleDisconnectModal([id])}
                    color="#9A9A9A"
                />
            </Tooltip>
        ) : (
            <Tooltip title="Connect">
                <GridActionsCellItem
                    icon={<InsertLinkIcon />}
                    label="Connect"
                    onClick={() => toggleConnectModal([id])}
                    color="#9A9A9A"
                />
            </Tooltip>
        ),
    ]
}

export const DEFAULT_HIDDEN_STOCKED_PRODUCT_LIST_COLUMNS = {
    avgInvCost: false,
    currentMarketCost: false,
    costUsed: false,
    margin: false,
    minMarginThreshold: false,
    maxMarginThreshold: false,
}

export const DEFAULT_HIDDEN_EDIT_STOCKED_PRODUCT_LIST_COLUMNS = {
    masterDescription: false,
    catalog: false,
    stockType: false,
    inventoryUOM: false,
    categoryName: true,
    isSuggestedPriceUpdate: false,
    autoPriceUpdate: false,
    autoSuggestedPo: false,
    minMarginThreshold: false,
    maxMarginThreshold: false,
    generatedBarcode: false,
    mappedVendors: false,
    isConnected: false,
    isFractionalProduct: false,
    isTaxableProduct: false,
    isDiscountableProduct: false,
    isAutoSuggestedPO: false,
    isAutoPriceUpdate: false,
}

export const YES_NO_OPTIONS = {
    labelKey: "name",
    valueKey: "value",
    options: [
        { name: "Yes", value: "YES" },
        { name: "No", value: "NO" },
    ],
}

export const STOCK_OPTIONS = {
    labelKey: "name",
    valueKey: "value",
    options: [
        { name: "Instock", value: "INSTOCK" },
        { name: "Reorder", value: "REORDER" },
        { name: "Must Reorder", value: "MUST_REORDER" },
        { name: "Out Of Stock", value: "OUT_OF_STOCK" },
    ],
}

export const getFiltersDropdownData = (
    categoryList = [],
    vendorsList = [],
    catalogList = [],
    source = ""
) => {
    if (source === "service") {
        return [
            {
                dropdownLabel: "Catalog",
                filterApiKey: "catalogIds",
                labelKey: "catalogName",
                valueKey: "catalogId",
                options: catalogList,
            },
            {
                dropdownLabel: "Category",
                filterApiKey: "categoryNames",
                labelKey: "categoryName",
                valueKey: "categoryName",
                options: categoryList ?? [],
            },
            {
                dropdownLabel: "Vendors",
                filterApiKey: "vendorIds",
                labelKey: "compName",
                valueKey: "compId",
                options: vendorsList ?? [],
            },
        ]
    }
    return [
        {
            dropdownLabel: "Catalog",
            filterApiKey: "catalogIds",
            labelKey: "catalogName",
            valueKey: "catalogId",
            options: catalogList,
        },
        {
            dropdownLabel: "Status",
            filterApiKey: "statuses",
            ...STOCK_OPTIONS,
        },
        {
            dropdownLabel: "Category",
            filterApiKey: "categoryNames",
            labelKey: "categoryName",
            valueKey: "categoryName",
            options: categoryList ?? [],
        },
        {
            dropdownLabel: "Vendors",
            filterApiKey: "vendorIds",
            labelKey: "compName",
            valueKey: "compId",
            options: vendorsList ?? [],
        },
        {
            dropdownLabel: "Is Connected?",
            filterApiKey: "isConnected",
            ...YES_NO_OPTIONS,
        },
        {
            dropdownLabel: "Is Yard Product?",
            filterApiKey: "isYardProduct",
            ...YES_NO_OPTIONS,
        },
    ]
}

export const GetActionsForNS = ({
    setLoading,
    toggleDeleteModal,
    togglePrintModal,
    ...params
}) => {
    const id = params.row.id

    return [
        <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => toggleDeleteModal([id])}
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Print />}
            label="Print"
            onClick={() => togglePrintModal([id], true)}
            color="#9A9A9A"
        />,
    ]
}

export const DEFAULT_HIDDEN_COLUMNS_FOR_NONSTOCKED_PRODUCTS = {
    stockType: false,
    availableStock: false,
    orderedQuantity: false,
    avgInvCost: false,
    currentMarketCost: false,
    costUsed: false,
    margin: false,
    generatedBarcode: false,
    barcode: false,
    vendorList: false,
    isTaxableProduct: false,
    isDiscountableProduct: false,
    isSuggestedPriceUpdate: false,
}

export const GetActionsForService = ({
    setLoading,
    toggleDeleteModal,
    togglePrintModal,
    ...params
}) => {
    const id = params.row.id

    let navigate = useNavigate()

    return [
        <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={(params) =>
                navigate(
                    `/inventory/product-details?sku=${id}&edit=true&type=SERVICE`
                )
            }
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => toggleDeleteModal([id])}
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Print />}
            label="Print"
            onClick={() => togglePrintModal([id], true)}
            color="#9A9A9A"
        />,
    ]
}

export const DEFAULT_HIDDEN_COLUMNS_FOR_SERVICE_PRODUCTS = {
    catalog: false,
    stockType: false,
    purchaseCost: false,
    margin: false,
    isTaxableProduct: false,
    isDiscountableProduct: false,
}
