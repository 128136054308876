import NumberFormatterText from "../components/NumberFormatterText"

export const DEFAULT_TABLE_SIZE = 20
export const a4PrintUrlGet = "/order/pdf/generate/po?"

export const STOCK_TYPE_CHARGE = "CHARGE"
export const STOCK_TYPE_DISCOUNT = "DISCOUNT"

export const PAYMENT_STATUS_MAP = {
    Draft: "0",
    Open: "1",
    "Partially Billed": 2,
    Completed: 3,
    Closed: 4,
    PARTIALLY_PAID: 2,
}
export const RECEIVING_STATUS_MAP = {
    Draft: 5,
    Open: 0,
    "Partially Billed": 1,
    Completed: 2,
    Closed: 6,
}

export const SO_STATUS_ENUM = {
    OPEN: "Open",
    PARTIALLY_INVOICED: "Partially Invoiced",
    INVOICED: "Invoiced",
    COMPLETED: "Completed",
    CLOSED: "Closed",
    VOID: "Voided",
}
export const SO_STATUS_COLOR_CODES = {
    Open: 0,
    "Partially Invoiced": 1,
    Invoiced: 1,
    Completed: 2,
    Voided: 3,
    Closed: 4,
}
export const RETURN_STATUS_ENUM = {
    Open: "Open",
    Completed: "Returned",
}
export const RETURN_STATUS_COLOR_CODES = {
    Open: 0,
    Returned: 2,
}
export const REFUND_STATUS_ENUM = {
    OPEN: "Not Refunded",
    PARTIALLY_REFUNDED: "Partially Refunded",
    REFUNDED: "Refunded",
}
export const REFUND_STATUS_COLOR_CODES = {
    "Not Refunded": 0,
    "Partially Refunded": 1,
    Refunded: 2,
}

export const DELIVERY_STATUS_ENUM = {
    OPEN: "Yet to Deliver",
    PARTIALLY_DELIVERED: "Partially Delivered",
    DELIVERED: "Delivered",
    VOID: "Voided",
}
export const RECEIVE_STATUS_ENUM = {
    OPEN: "Yet to Receive",
    PARTIALLY_RECEIVED: "Partially Received",
    RECEIVED: "Received",
    VOID: "Voided",
}
export const RECEIVE_STATUS_COLOR_CODES = {
    "Yet to Receive": 0,
    "Partially Received": 1,
    Received: 2,
    Voided: 3,
}
export const DELIVERY_STATUS_COLOR_CODES = {
    "Yet to Deliver": 0,
    "Partially Delivered": 1,
    Delivered: 2,
    Voided: 4,
}

export const BOL_STATUS_ENUM = {
    OPEN: "Planned",
    PARTIALLY_DELIVERED: "Partially Delivered",
    DELIVERED: "Delivered",
    VOID: "Voided",
}
export const BOL_STATUS_COLOR_CODES = {
    Planned: 0,
    "Partially Delivered": 1,
    Delivered: 2,
    Voided: 4,
}

export const PAYMENT_STATUS_ENUM = {
    OPEN: "Unpaid",
    PARTIALLY_PAID: "Partially Paid",
    PAID: "Paid",
    OVERDUE: "Overdue",
    VOID: "Voided",
    DRAFT: "Draft",
    MARKED_AS_PAID: "Marked as Paid"
}
export const PAYMENT_STATUS_COLOR_CODES = {
    Unpaid: 0,
    "Partially Paid": 1,
    Paid: 2,
    Draft: 3,
    Voided: 4,
    Overdue: 5,
    "Marked as Paid": 6
}

export const DELIVERY_MODE_ENUM = {
    CARRY_OUT: "Carry Out",
    PICK_UP: "Pick Up",
    DELIVERY: "Delivery",
    DROPSHIP: "Dropship",
}

export const SEARCH_BY_RETAILER = "retailer"
export const SEARCH_BY_VENDOR = "vendor"
export const DELIVERY_MODE_OPTIONS = [
    {
        label: "Delivery",
        value: "DELIVERY",
    },
    {
        label: "Dropship",
        value: "DROPSHIP",
    },
]

export const ESTIMATE_STATUS_ENUM = {
    open: "Open",
    "partial converted to so": "Partially Converted",
    "converted to so": "Fully Converted",
    "missing prices": "Missing Prices",
    submitted: "Submitted",
    closed: "Closed",
    completed: "Fully Converted",
    "partially invoiced": "Partially Converted",
    partially_invoiced: "Partially Converted",
}
export const ESTIMATE_STATUS_COLOR_CODES = {
    Open: 0,
    "Missing Prices": 0,
    Submitted: 0,
    "Partially Converted": 1,
    "Fully Converted": 2,
    Completed: 2,
    Closed: 6,
}

export const PO_STATUS_ENUM = {
    OPEN: "Open",
    PARTIALLY_BILLED: "Partially Billed",
    COMPLETED: "Completed",
    DRAFT: "Draft",
}

export const PO_STATUS_COLOR_CODES = {
    Open: 0,
    "Partially Billed": 1,
    Completed: 2,
    Draft: 3,
}

export const chargesColumnsForBills = [
    {
        title: "Charge ID",
        field: "id",
        formatter: ({ row, value }) => value || row.sku,
    },
    {
        title: "Description",
        field: "description",
        formatter: ({ row, value }) => value || row.sku,
    },
    {
        title: "Notes",
        field: "notes",
    },
    {
        title: "Amount",
        field: "amount",
        align: "right",
        formatter: ({ row, value }) => (
            <NumberFormatterText value={value || row.sellingPrice} />
        ),
    },
]
export const discountsColumnsForBills = [
    {
        title: "Discount ID",
        field: "id",
        formatter: ({ row, value }) => value || row.sku,
    },
    {
        title: "Description",
        field: "description",
        formatter: ({ row, value }) => value || row.sku,
    },
    {
        title: "Notes",
        field: "notes",
    },
    {
        title: "Amount",
        field: "amount",
        align: "right",
        formatter: ({ row, value }) => (
            <NumberFormatterText value={value || row.sellingPrice} />
        ),
    },
]
