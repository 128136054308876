// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const InvoicesFiltersContext = createContext()

// Create the provider component
export default function InvoicesFiltersProvider({ children }) {
    const [filterCustomer, setFilterCustomer] = useState()
    const [filterProject, setFilterProject] = useState()
    const [filterSalespersons, setFilterSalespersons] = useState([])
    const [filterDeliveryStatuses, setFilterDeliveryStatuses] = useState([])
    const [filterPaymentStatuses, setFilterPaymentStatuses] = useState([])
    const [filterfromDate, setFilterFromDate] = useState(null)
    const [filtertoDate, setFilterToDate] = useState(null)
    const [filterfromAmount, setFilterFromAmount] = useState()
    const [filtertoAmount, setFilterToAmount] = useState()

    const value = {
        filterCustomer,
        setFilterCustomer,
        filterProject,
        setFilterProject,
        filterSalespersons,
        setFilterSalespersons,
        filterDeliveryStatuses,
        setFilterDeliveryStatuses,
        filterPaymentStatuses,
        setFilterPaymentStatuses,
        filterfromDate,
        setFilterFromDate,
        filtertoDate,
        setFilterToDate,
        filterfromAmount,
        setFilterFromAmount,
        filtertoAmount,
        setFilterToAmount,
    }

    return (
        <InvoicesFiltersContext.Provider value={value}>
            {children}
        </InvoicesFiltersContext.Provider>
    )
}
