import React from "react"
import "../../styles/action-bar.scss"
import { Button, Paper, Typography } from "@mui/material"
import { Delete } from "@mui/icons-material"

export function ActionBar({ selectedProductsCount = 0, handleDelete }) {
    return (
        <Paper className="bb-so-actionbar">
            <div className="flex width-100">
                <Typography className="actionbar-menu-text">
                    Selected {selectedProductsCount} Products
                </Typography>
                {[
                    {
                        label: "Delete",
                        onClick: handleDelete,
                        icon: <Delete />,
                        className: "actionbar-delete-btn",
                    },
                ].map((item) => (
                    <Button
                        variant="contained"
                        startIcon={item.icon}
                        key={item.label}
                        type="primary"
                        className={`actionbar-menu-button actionbar-button ${
                            item.className ?? ""
                        }`}
                        onClick={item.onClick}
                        disabled={item.disabled}
                    >
                        {item.label}
                    </Button>
                ))}
            </div>
        </Paper>
    )
}
