import { useEffect, useMemo, useState } from "react"
import { getAllProducts, updateTenantProduct } from "../../../utils/catalog"
import {
    Backdrop,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import { chargesColumns } from "./chargesColumns"
import ToolBar from "./ToolBar"
import { ActionBar } from "./ActionBar"
import DataGridWithPaginationSupport from "../../../components/DataGridWithPaginationSupport"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { Delete, Edit } from "@mui/icons-material"
import { DEFAULT_TABLE_SIZE } from "../../../utils/constants"
import { ConfirmModal } from "../../../components/ConfirmModal"
import { cloneDeep } from "lodash"
import { enqueueSnackbar } from "notistack"
import AddCharge from "./AddCharge"
import DeleteModal from "../stock-products/list/modals/DeleteModal"
import "./../../styles/list-styles.scss"

export const GetChargesActions = (params, downloadCatalog, deleteC) => {
    return [
        <GridActionsCellItem
            icon={<Edit />}
            label="Download"
            color="#9A9A9A"
            onClick={() => downloadCatalog(params.row)}
        />,
        <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            color="#9A9A9A"
            onClick={() => deleteC([params.row.id])}
        />,
    ]
}

export default function Charges() {
    const session = JSON.parse(localStorage.getItem("userDetails"))
    const companyId = session.companyId
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState({})
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: DEFAULT_TABLE_SIZE,
    })
    const [selectedRows, setSelectedRows] = useState([])
    const [openAddProductModal, setOpenAddProductModal] = useState(false)
    const [showNonSelectedModal, setShowNonSelectedModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedItemsToUpdate, setSelectedItemsToUpdate] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)

    const [catalogList, setCatalogList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [vendorsList, setVendorsList] = useState([])

    const [toolbarCallback, setToolbarCallback] = useState(null)

    const [description, setDescription] = useState("")

    useEffect(() => {
        getCharges()
    }, [])

    const getCharges = (reqBody) => {
        const requestBody = reqBody ?? {
            companyId: companyId,
            pageCount: 20,
            pageNumber: 0,
            filter: [
                {
                    field: "stockType",
                    value: "CHARGE",
                },
            ],
        }
        setLoading(true)
        getAllProducts(requestBody).then((response) => {
            const data = response.body?.data?.map((d, index) => {
                d.id = d.sku
                return d
            })
            const tData = {
                ...response.body,
                data,
            }
            setTableData(tData)
            setLoading(false)
        })
    }

    const updateSearchParams = (searchInput) => {
        const requestBody = {
            companyId: companyId,
            pageCount: 20,
            pageNumber: 0,
            filter: [
                {
                    field: "stockType",
                    value: "CHARGE",
                },
            ],
        }
        if (searchInput) {
            requestBody.keyword = searchInput
        }
        // resetSelectedFilters()
        getCharges(requestBody)
    }

    const editCharge = (row) => {
        setShowEditModal(true)
        setSelectedRows(row)
    }

    // const selectedProductsCount = () => selectedRows.length

    const selectedProductsCount = useMemo(
        () => selectedRows.length,
        [selectedRows]
    )

    const deleteItem = (chargeIds) => {
        setSelectedItemsToUpdate(getSelectedItems(chargeIds))
        setShowDeleteModal(chargeIds?.length > 0)
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRows(selectionModel)
    }

    const getSelectedItems = (chargeIds) => {
        if (!chargeIds || chargeIds.length === 0) {
            setShowNonSelectedModal(true)
            return []
        }
        return tableData.data?.filter((d) => chargeIds.includes(d.id))
    }

    const handleChange = (e) => {
        setDescription(e.target.value)
    }

    const updateCharge = () => {
        const reqBody = cloneDeep(selectedRows)
        reqBody["description"] = description
        setLoading(true)
        updateTenantProduct(reqBody).then((response) => {
            setLoading(false)
            setShowEditModal(false)
            if (response.body.data) {
                enqueueSnackbar(
                    "Charge updated successfully!",
                    {
                        variant: "success",
                    },
                    1000
                )
                getCharges()
            } else {
                enqueueSnackbar(
                    "Error occured while updating charges",
                    {
                        variant: "error",
                    },
                    1000
                )
            }
        })
    }

    const handleDialogClose = () => {
        getCharges()
        setOpenAddProductModal(false)
    }

    const enrichColumnDefs = (columns) => {
        return columns.map((col) => {
            const column = { ...col }
            if (column.field === "actions") {
                column.getActions = (params) =>
                    GetChargesActions(params, editCharge, deleteItem)
            }
            return column
        })
    }
    return (
        <div className="stock-products-list-container stock-products-container">
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToolBar
                title={"Charges"}
                setSearchParams={updateSearchParams}
                updateCurrentParams={(clearToolbarParams) =>
                    setToolbarCallback({ clearToolbarParams })
                }
                setOpenAddProductModal={(value) => {
                    setOpenAddProductModal(value)
                }}
            />

            <ActionBar
                selectedProductsCount={selectedProductsCount}
                handleDelete={() => deleteItem(selectedRows)}
            />
            <DataGridWithPaginationSupport
                headCells={enrichColumnDefs(chargesColumns)}
                rows={tableData.data}
                rowCount={tableData.totalElements || 0}
                {...{
                    // loading,
                    setPaginationModel,
                    paginationModel,
                }}
                paginationMode="client"
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                selectionModel={selectedRows}
                // disableColumnFilter
            />
            {showEditModal && (
                <ConfirmModal
                    open={showEditModal}
                    maxWidth="md"
                    toggleModal={() => setShowEditModal(false)}
                    onConfirm={() => updateCharge()}
                    showNoBtn={true}
                    confirmBtnLabel="Update Charges"
                    title="Edit Charge"
                    bodyContent={
                        <div>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <TextField
                                        disabled
                                        required
                                        fullWidth
                                        label="Charge ID"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        name="sku"
                                        value={selectedRows.id}
                                        // onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={8} lg={8}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Description"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        name="sku"
                                        defaultValue={selectedRows.name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    }
                />
            )}

            {openAddProductModal && (
                <AddCharge
                    setOpenAddProductModal={setOpenAddProductModal}
                    open={openAddProductModal}
                    companyId={companyId}
                    type="CHARGE"
                    handleDialogClose={handleDialogClose}
                />
            )}

            {showDeleteModal && (
                <DeleteModal
                    type={"Charge"}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    selectedItemsToUpdate={selectedItemsToUpdate}
                    setLoading={setLoading}
                    companyId={companyId}
                    refreshResult={() => getCharges()}
                />
            )}

            {showNonSelectedModal && (
                <ConfirmModal
                    open={showNonSelectedModal}
                    maxWidth="xs"
                    toggleModal={() => setShowNonSelectedModal(false)}
                    onConfirm={() => setShowNonSelectedModal(false)}
                    showNoBtn={false}
                    confirmBtnLabel="Ok"
                    title="Select SKU"
                    bodyContent={
                        <Typography gutterBottom>
                            Please select at-least one sku
                        </Typography>
                    }
                />
            )}
        </div>
    )
}
