// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const BolFiltersContext = createContext()

// Create the provider component
export default function BolFiltersProvider({ children }) {
    const [filterCustomers, setFilterCustomers] = useState([])
    const [filterDeliveryStatuses, setFilterDeliveryStatuses] = useState([])
    const [filterDeliveryModes, setFilterDeliveryModes] = useState([])
    const [filterfromDate, setFilterFromDate] = useState(null)
    const [filtertoDate, setFilterToDate] = useState(null)

    const value = {
        filterCustomers,
        setFilterCustomers,
        filterDeliveryStatuses,
        setFilterDeliveryStatuses,
        filterDeliveryModes,
        setFilterDeliveryModes,
        filterfromDate,
        setFilterFromDate,
        filtertoDate,
        setFilterToDate,
    }

    return (
        <BolFiltersContext.Provider value={value}>
            {children}
        </BolFiltersContext.Provider>
    )
}
