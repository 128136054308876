import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridCellModes,
} from "@mui/x-data-grid"
import { useCallback, useEffect, useState } from "react"

function CustomToolbar({ selectedFiltersComponent }) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            {selectedFiltersComponent && selectedFiltersComponent}
        </GridToolbarContainer>
    )
}

export default function DataGridWithPaginationSupport({
    loading,
    headCells,
    rows = [],
    rowCount,
    paginationModel,
    setPaginationModel,
    checkboxSelection,
    isRowSelectable,
    isRowSelectableCondition,
    setRowClass,
    handleProcessRowUpdateError,
    processRowUpdate,
    rowModesModel,
    onRowModesModelChange,
    editMode = "",
    setApiContext,
    customRowHeight = 36,
    selectedFiltersComponent = null,
    ...props
}) {
    const apiRef = useGridApiRef()
    if (Object.keys(apiRef).length > 0) {
        if (setApiContext) setApiContext(apiRef)
    }

    const [cellModesModel, setCellModesModel] = useState({})
    const handleCellClick = useCallback((params, event) => {
        if (!params.isEditable) {
            return
        }

        // Ignore portal
        if (
            event.target.nodeType === 1 &&
            !event.currentTarget.contains(event.target)
        ) {
            return
        }

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {}
                        ),
                    }),
                    {}
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({
                            ...acc,
                            [field]: { mode: GridCellModes.View },
                        }),
                        {}
                    ),
                    [params.field]: { mode: GridCellModes.Edit },
                },
            }
        })
    }, [])

    const handleCellModesModelChange = useCallback((newModel) => {
        setCellModesModel(newModel)
    }, [])

    useEffect(() => {
        apiRef.current.setRowSelectionModel([])
    }, [rows])

    return (
        <DataGrid
            className="co-tabble-wrapper"
            // sx={{ minHeight: "20ch" }}
            sx={{
                ".MuiDataGrid-virtualScrollerContent": {
                    height: "initial !important",
                },
            }}
            localeText={{
                noRowsLabel: "No Records Found!",
                noResultsOverlayLabel: "No Records Found!",
            }}
            disableVirtualization
            rowHeight={customRowHeight}
            headerHeight={64}
            loading={loading}
            // slots={{ toolbar: GridToolbar }}
            // slots={{ toolbar: CustomToolbar }}
            slots={{
                toolbar: () => (
                    <CustomToolbar
                        selectedFiltersComponent={selectedFiltersComponent}
                    />
                ),
            }}
            slotProps={{
                toolbar: { selectedFiltersComponent },
            }}
            rows={rows.map((data, id) => ({ id, ...data }))}
            columns={headCells}
            paginationMode="server"
            server
            density="compact"
            rowCount={rowCount}
            pageSizeOptions={[20, 30, 50]}
            checkboxSelection={checkboxSelection}
            isRowSelectable={(params) => {
                return isRowSelectableCondition
                    ? isRowSelectableCondition(params)
                    : isRowSelectable
                    ? !!params.row.buyerCompanyName
                    : true
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={(resp) => {
                setPaginationModel(resp)
            }}
            disableRowSelectionOnClick
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={onRowModesModelChange}
            getRowClassName={(params) => {
                if (setRowClass) {
                    return setRowClass(params)
                }
            }}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            apiRef={apiRef}
            {...props}
        />
    )
}
