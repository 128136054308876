import { List, ListItem, ListItemText, Typography } from "@mui/material"
import { ConfirmModal } from "../../../../../components/ConfirmModal"
import React, { useCallback } from "react"
import { enqueueSnackbar } from "notistack"
import { deleteProduct } from "../../../../../utils/catalog"

export const StockedProductModalBody = ({ selectedItemsToUpdate }) => {
    return (
        <List className="selected-items-detele-list">
            {selectedItemsToUpdate?.map((item, index) => (
                <ListItem key={`selectedItemsToUpdate-${index}`} disablePadding>
                    <ListItemText
                        primary={
                            selectedItemsToUpdate?.length > 1
                                ? `${index + 1}. ${item.sku}`
                                : `${item.sku}`
                        }
                        secondary={item.name}
                    />
                </ListItem>
            ))}
        </List>
    )
}

const DeleteModal = ({
    type,
    showDeleteModal,
    setShowDeleteModal,
    selectedItemsToUpdate,
    setLoading,
    companyId,
    refreshResult,
}) => {
    const deleteSKUs = useCallback(() => {
        setLoading(true)
        setShowDeleteModal(false)
        const requestBody = {
            companyId,
            skuList: selectedItemsToUpdate.map(({ sku }) => sku),
        }
        deleteProduct(requestBody).then(
            (res) => {
                setLoading(false)
                enqueueSnackbar(`${type}'s deleted successfully`, {
                    variant: "success",
                })
                refreshResult()
            },
            () => {
                setLoading(false)
                setShowDeleteModal(true)
                enqueueSnackbar("Deletion failed", {
                    variant: "error",
                })
            }
        )
    }, [
        setLoading,
        setShowDeleteModal,
        companyId,
        selectedItemsToUpdate,
        refreshResult,
        type,
    ])

    return (
        <ConfirmModal
            open={showDeleteModal}
            maxWidth="xs"
            toggleModal={() => setShowDeleteModal(false)}
            onConfirm={deleteSKUs}
            confirmBtnLabel={"Delete"}
            title={
                selectedItemsToUpdate.length === 1
                    ? `Delete ${type}`
                    : `Delete Multiple ${type}s`
            }
            bodyContent={
                <>
                    <Typography gutterBottom>
                        Are you sure you want to delete{" "}
                        {selectedItemsToUpdate.length === 1
                            ? `this ${type}`
                            : `the following ${type}s`}
                        ?
                    </Typography>
                    <StockedProductModalBody
                        selectedItemsToUpdate={selectedItemsToUpdate}
                    />
                </>
            }
        />
    )
}

export default DeleteModal
