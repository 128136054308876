// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const SOFiltersContext = createContext()

// Create the provider component
export default function SOFiltersProvider({ children }) {
    const [filterCustomer, setFilterCustomer] = useState()
    const [filterProject, setFilterProject] = useState()
    const [filterSalespersons, setFilterSalespersons] = useState([])
    const [filterStatuses, setFilterStatuses] = useState([])
    const [filterfromDate, setFilterFromDate] = useState(null)
    const [filtertoDate, setFilterToDate] = useState(null)

    const value = {
        filterCustomer,
        setFilterCustomer,
        filterProject,
        setFilterProject,
        filterSalespersons,
        setFilterSalespersons,
        filterStatuses,
        setFilterStatuses,
        filterfromDate,
        setFilterFromDate,
        filtertoDate,
        setFilterToDate,
    }

    return (
        <SOFiltersContext.Provider value={value}>
            {children}
        </SOFiltersContext.Provider>
    )
}
