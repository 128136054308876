import React, { useCallback } from "react"
import {
    Button,
    FormControl,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined"
import { useNavigate } from "react-router-dom"
import { NestedDropdown } from "mui-nested-menu"
// import TextField from "@mui/material/TextField"
import { Settings } from "@mui/icons-material"
import ProductSearchBar from "../non-stock-products/ProductSearchBar"

const ToolBar = ({ title, setSearchParams, setOpenAddProductModal }) => {
    let navigate = useNavigate()

    return (
        <Paper elevation={3}>
            <Box
                className={"sku-list-header"}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    padding: 1,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        "flex-direction": "row",
                        "column-gap": "20px",
                        "margin-right": "auto",
                    }}
                >
                    <Typography variant={"h6"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            aria-label="delete"
                        >
                            <KeyboardBackspaceOutlinedIcon />
                        </IconButton>

                        {title}
                    </Typography>
                    <FormControl className={"so-trigger-btn"}>
                        <ProductSearchBar
                            handleDebounce={(value) => setSearchParams(value)}
                        />
                    </FormControl>
                </div>
                <div className={"sku-list-header-input-wrap"}>
                    <Button
                        sx={{ ml: "auto" }}
                        onClick={() => setOpenAddProductModal(true)}
                        variant="contained"
                        style={{ textTransform: "none" }}
                    >
                        + Add Charge
                    </Button>
                </div>
            </Box>
        </Paper>
    )
}

export default ToolBar
