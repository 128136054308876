import React from "react"
import { Link, useLocation } from "react-router-dom"

export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
})

export const usdPrice = {
    type: "number",
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: "font-tabular-nums",
}

export const RenderLink = ({ url, value }) => {
    return (
        <Link to={url}>
            <b>{value}</b>
        </Link>
    )
}

export const printPdf = (pdfData) => {
    let byteCharacters = atob(pdfData)
    let byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    let blob = new Blob([byteArray], { type: "application/pdf" })
    let url = window.URL.createObjectURL(blob)
    printPreview(url)
}

export const exportPdf = (response, fileName) => {
    let byteCharacters = atob(response.data)
    let byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    let blob = new Blob([byteArray], { type: "application/pdf" })
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement("a")
    document.body.appendChild(a)
    a.href = url
    a.download = fileName ?? response.fileName
    a.click()
    document.body.removeChild(a)
}

const printPreview = (fileLink) => {
    const iframe = document.createElement("iframe")
    iframe.src = fileLink
    iframe.id = "print_pdf"
    iframe.name = "print_pdf"
    iframe.style.display = "none"
    iframe.style.visibility = "hidden"

    iframe.onload = () => {
        iframe.contentWindow.addEventListener("afterprint", (evt) => {
            document.body.removeChild(iframe)
        })
        iframe.contentWindow.print()
    }

    document.body.appendChild(iframe)
}

export const printExcel = (data, name = "temp.xlsx") => {
    let byteCharacters = atob(data)
    let byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    let blob = new Blob([byteArray], {
        type: "application / vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement("a")
    document.body.appendChild(a)
    a.href = url
    a.download = name
    a.click()
    document.body.removeChild(a)
}

export const useSearchQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}
export function TextHighlighter({ text, highlight }) {
    if (!text) return text
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
        <span>
            {" "}
            {parts.map((part, i) => (
                <span
                    key={i}
                    style={
                        part.toLowerCase() === highlight.toLowerCase()
                            ? { fontWeight: "bold" }
                            : {}
                    }
                >
                    {part}
                </span>
            ))}{" "}
        </span>
    )
}
export function amountToCents(amount) {
    // Convert amount to cents
    const cents = Math.round(amount * 100)
    return cents
}

export function restrictToTwoDecimals(value) {
    // Convert the value to a number if it's not already
    value = Number(value)
    // Round to two decimals using toFixed
    return Math.round(value * 100) / 100
}
