import React, { Fragment, useState } from "react"
import "./hover-dropdown.scss"
import { NavLink } from "react-router-dom"

function HoverDropdown(props) {
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const handleMouseEnter = () => {
        setDropdownVisible(true)
    }

    const handleMouseLeave = () => {
        setDropdownVisible(false)
    }

    return (
        <div className="hover-dropdown">
            <div
                className="menu"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="hover-link">
                    <NavLink
                        className="text-decoration-none main-nav-link"
                        to={props.menu.url}
                        target={props.menu.newTab && "_blank"}
                    >
                        {props.menu.name}
                    </NavLink>
                </div>
                {dropdownVisible && props.menu.children && (
                    <DropdownMenu list={props.menu.children} />
                )}
            </div>
        </div>
    )
}

function DropdownMenu(props) {
    return (
        <div className="dropdown-menu-hover">
            <ul>
                {props.list.map((item) => (
                    <Fragment key={item.name + "_" + item.url}>
                        <li className="header-memu-list">
                            <NavLink
                                className="text-decoration-none nav-link"
                                to={item.url}
                                target={item.newTab && "_blank"}
                            >
                                {item.name}
                            </NavLink>
                        </li>
                        {item.children && (
                            <DropdownSubMenu list={item.children} />
                        )}
                    </Fragment>
                ))}
            </ul>
        </div>
    )
}
function DropdownSubMenu(props) {
    return (
        <ul>
            {props.list.map((item) => (
                <Fragment key={item.name + "_" + item.url}>
                    <li className="header-sub-menu-list">
                        <NavLink
                            className="text-decoration-none nav-link"
                            to={item.url}
                            style={{ paddingLeft: 35 }}
                        >
                            {"> " + item.name}
                        </NavLink>
                    </li>
                    {item.children && <DropdownMenu list={item.children} />}
                </Fragment>
            ))}
        </ul>
    )
}

export default HoverDropdown
