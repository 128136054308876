import { HighlightOff } from "@mui/icons-material"
import {
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
} from "@mui/material"
import { Fragment, useState } from "react"
import { styled } from "@mui/material/styles"
import { createLocalProduct } from "../../../utils/catalog"
import { enqueueSnackbar } from "notistack"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}))

export default function AddDiscount({
    setOpenAddProductModal,
    companyId,
    type,
    open,
    handleDialogClose,
}) {
    const [loading, setLoading] = useState(false)
    const [skuName, setSkuName] = useState("")
    const [description, setDescription] = useState("")
    const [allowAddNewProduct, setAllowAddNewProduct] = useState(false)

    const skuChange = (e) => {
        setSkuName(e.target.value)
    }

    const descChange = (e) => {
        setDescription(e.target.value)
    }

    const onSubmit = () => {
        const requestBody = {
            companyId: companyId,
            name: description,
            sku: skuName,
            stockType: type,
            description: description,
        }
        setLoading(true)
        createLocalProduct(requestBody).then((response) => {
            console.log("createLocalProduct", response)
            setLoading(false)
            if (!response.body.data) {
                enqueueSnackbar("Discount added successfully!", {
                    variant: "success",
                })
            } else {
                enqueueSnackbar(response.data, {
                    variant: "warning",
                })
            }
            if (allowAddNewProduct) {
                setSkuName("")
                setDescription("")
            } else {
                handleDialogClose()
            }
        })
    }
    return (
        <Fragment>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <BootstrapDialog
                fullWidth
                maxWidth={"sm"}
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>Add Discount</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <HighlightOff />
                </IconButton>
                <DialogContent dividers>
                    <div className="content">
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Discount ID"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    name="sku"
                                    value={skuName}
                                    onChange={skuChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Description"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    name="sku"
                                    value={description}
                                    onChange={descChange}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        label="Add Another Discount"
                        control={<Checkbox />}
                        value={allowAddNewProduct}
                        onChange={(e) =>
                            setAllowAddNewProduct(e.target.checked)
                        }
                    />
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setOpenAddProductModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onSubmit()}
                        disabled={!(skuName && description)}
                    >
                        Add Discount
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}
