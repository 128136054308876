import React from "react"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
// import CloseIcon from "@mui/icons-material/Close"
import { HighlightOff } from "@mui/icons-material"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}))

export function ConfirmModal({
    open,
    toggleModal,
    onConfirm,
    title,
    bodyContent,
    maxWidth,
    confirmBtnLabel,
    confirmBtnDisabled = false,
    showNoBtn = true,
    noBtnLabel = "Cancel",
    showFooter = true,
    sx,
}) {
    return (
        <React.Fragment>
            <BootstrapDialog
                fullWidth
                maxWidth={maxWidth ?? "md"}
                onClose={toggleModal}
                open={open}
                sx={sx}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={toggleModal}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <HighlightOff />
                </IconButton>
                <DialogContent dividers>{bodyContent}</DialogContent>
                {showFooter && (
                    <DialogActions>
                        {/* <Button onClick={onConfirm} disabled={confirmBtnDisabled}>
                        {confirmBtnLabel ?? "Yes"}
                    </Button>
                    {showNoBtn && (
                        <Button autoFocus onClick={toggleModal}>
                            No
                        </Button>
                    )} */}
                        {showNoBtn && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={toggleModal}
                            >
                                {noBtnLabel}
                            </Button>
                        )}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onConfirm}
                            disabled={confirmBtnDisabled}
                        >
                            {confirmBtnLabel ?? "Yes"}
                        </Button>
                    </DialogActions>
                )}
            </BootstrapDialog>
        </React.Fragment>
    )
}
