import React, { useState, useMemo, useEffect, Fragment } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Grid,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    TextField,
    MenuItem,
    InputAdornment,
    Autocomplete,
    debounce,
    Backdrop,
    CircularProgress,
    Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { HighlightOff, Search } from "@mui/icons-material"
import "./add-product.css"
import {
    createLocalProduct,
    queryProducts,
    getProductDetails,
    getUOMProductList,
    getProductCategories,
    getCompanyCatalog,
    mapUnmapProducts,
    getInventoryProductSettings,
    getMasterUOM,
} from "../../utils/catalog"
import { enqueueSnackbar } from "notistack"
import VendorSelector from "../VendorSelector/VendorSelector"
import MarginInput from "../MarginInput"
import QuantityInput from "../QuantityInput"
import PriceAndCostInput from "../PriceAndCostInput"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}))

const BooleanInput = (props) => {
    const { index, label, field, value, onChange } = props

    return (
        <Grid item xs={4} sm={4} md={3} lg={2}>
            <FormControl>
                <FormLabel
                    id={`radio-buttons-group-label${index}`}
                    sx={{ fontSize: "12px" }}
                >
                    {label}
                </FormLabel>
                <RadioGroup row name={field} value={value} onChange={onChange}>
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                    />
                </RadioGroup>
            </FormControl>
        </Grid>
    )
}

export default function AddProduct({
    open,
    handleDialogClose,
    stockType = "STOCK",
    companyId,
    companyName,
    userId,
}) {
    const defaultProductDetails = {
        stockType: stockType,
        companyId: companyId,
        sku: "",
        description: "",
        purchaseCost: 0,
        sellingPrice: 0,
        barcode: "",
        margin: 25,
        companyName: companyName,
        userId: userId,
        isTaxableProduct: "true",
        isDiscountableProduct: "true",
        isYardProduct: "false",
        isSuggestedPriceUpdate: "true",
        autoSuggestedPo: "true",
        autoPriceUpdate: "true",
        // isFractionalProduct: "false",
        // allowFractional: "false",
        categoryName: "",
        currentStock: 0,
        purchasePackQuantity: 0,
        costMBFTPrice: 0,
        markup: Number((25 / (1 - 25 / 100)).toFixed(2)),
    }
    const [loading, setLoading] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [categoryType, setCategoryType] = useState("")
    const [catalogList, setCatalogList] = useState([])
    const [catalogId, setCatalogId] = useState()
    const [allowAddNewProduct, setAllowAddNewProduct] = useState(false)
    const [allowSearchForVendorProduct, setAllowSearchForVendorProduct] =
        useState(false)
    const [allowCostPerMBFT, setAllowCostPerMBFT] = useState(false)
    const [productDetails, setProductDetails] = useState(defaultProductDetails)
    const [selectedVendor, setSelectedVendor] = useState()
    const [selectedVendorProduct, setSelectedVendorProduct] = useState()
    const [inputValue, setInputValue] = useState("")
    const [options, setOptions] = useState([])
    const [masterUOMCategories, setMasterUOMCategories] = useState([])
    const [selectedUOMCategory, setSelectedUOMCategory] = useState("Count")
    const [uomUnitList, setUOMUnitList] = useState([])
    const [selectedUOM, setSelectedUOM] = useState("Each")
    const [selectedUOMProduct, setSelectedUOMProduct] = useState({})
    const [uomProductList, setUOMProductList] = useState([])
    const [inventoryMargin, setInventoryMargin] = useState(25)

    useEffect(() => {
        setLoading(true)
        getMasterUOM()
            .then((res) => {
                setLoading(false)
                if (res?.data?.data) {
                    setMasterUOMCategories(res?.data?.data)
                    const uomList =
                        res?.data?.data.find(
                            (cat) => cat.uomCategory === "Count"
                        ).uom || []
                    setUOMUnitList(uomList)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }, [])

    useEffect(() => {
        setLoading(true)
        getInventoryProductSettings(companyId)
            .then((res) => {
                setLoading(false)
                if (res?.data?.data) {
                    const margin = res?.data?.data?.margin || 25
                    setInventoryMargin(margin)
                    setProductDetails((prevFormData) => ({
                        ...prevFormData,
                        margin: margin,

                        markup: Number(
                            (margin / (1 - margin / 100)).toFixed(2)
                        ),
                    }))
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }, [companyId])

    useEffect(() => {
        setLoading(true)
        getUOMProductList()
            .then((res) => {
                setLoading(false)
                setUOMProductList(res?.data?.data ?? [])
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }, [])

    useEffect(() => {
        setLoading(true)
        getProductCategories({ companyId })
            .then((res) => {
                setLoading(false)
                setCategoryList(res?.data?.data ?? [])
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }, [companyId])

    useEffect(() => {
        setLoading(true)
        getCompanyCatalog(companyId)
            .then((res) => {
                setLoading(false)
                setCatalogList(res?.data?.data ?? [])
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }, [companyId])

    const handleChange = (event) => {
        const { name, value } = event.target
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const booleanInputOptions = [
        {
            label: "Taxable",
            field: "isTaxableProduct",
            onChange: handleChange,
        },
        {
            label: "Discountable",
            field: "isDiscountableProduct",
            onChange: handleChange,
        },
        {
            label: "Yard Product",
            field: "isYardProduct",
            onChange: handleChange,
        },
        {
            label: "Auto Price Update",
            field: "autoPriceUpdate",
            onChange: handleChange,
        },
        {
            label: "Update Sugg. Retail",
            field: "isSuggestedPriceUpdate",
            onChange: handleChange,
        },
        {
            label: "Auto Suggested PO",
            field: "autoSuggestedPo",
            onChange: handleChange,
        },
    ]

    const handleChangeUOMCategory = (value) => {
        setSelectedUOMCategory(value)
        const uomList =
            masterUOMCategories.find((cat) => cat.uomCategory === value).uom ||
            []
        setUOMUnitList(uomList)
        setSelectedUOM(uomList[0]?.name || "")
    }

    const onChangePrice = (value) => {
        if (!value) {
            value = 0
        }
        const _margin = ((value - productDetails.purchaseCost) / value) * 100
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            sellingPrice: value,
            margin: _margin === 100 ? 99.99 : Number(_margin.toFixed(2)),
        }))
    }

    const calculateSellingPrice = (purchaseCost, margin) => {
        const _price = purchaseCost / (1 - margin * 0.01)
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            sellingPrice: Number(_price.toFixed(5)),
        }))
    }

    const onChangeUOMProduct = (value) => {
        setSelectedUOMProduct(value)
        const _mbftCost = productDetails.costMBFTPrice
        const _eachCost =
            (_mbftCost * value.conversionFactor) / value.devideFactor
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            purchaseCost: Number(_eachCost.toFixed(5)),
        }))
        calculateSellingPrice(
            Number(_eachCost.toFixed(5)),
            productDetails["margin"]
        )
    }

    const onChangeCostPerMBFT = (value) => {
        if (!value) {
            value = 0
        }
        if (selectedUOMProduct) {
            const _eachCost =
                (value * selectedUOMProduct.conversionFactor) /
                selectedUOMProduct.devideFactor
            setProductDetails((prevFormData) => ({
                ...prevFormData,
                costMBFTPrice: value,
                purchaseCost: Number(_eachCost.toFixed(5)),
            }))
            calculateSellingPrice(
                Number(_eachCost.toFixed(5)),
                productDetails["margin"]
            )
        }
    }

    const onChangeCostPerUOM = (value) => {
        if (!value) {
            value = 0
        }
        calculateSellingPrice(value, productDetails["margin"])
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            purchaseCost: value,
        }))
        if (selectedUOMProduct) {
            const _mbftPrice =
                (value * selectedUOMProduct.devideFactor) /
                selectedUOMProduct.conversionFactor
            setProductDetails((prevFormData) => ({
                ...prevFormData,
                costMBFTPrice: Number(_mbftPrice.toFixed(5)),
            }))
        }
    }

    // Calculate Markup When Margin Change
    const onMarginChange = (value) => {
        if (!value) {
            value = 0
        }
        // Pls check as now we have disallow to enter more than 99.99 %
        const _markup = value === 100 ? value : value / (1 - value / 100)
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            margin: value,
            markup: Number(_markup.toFixed(2)),
        }))
        calculateSellingPrice(productDetails["purchaseCost"], value)
    }

    // Calculate Margin When Markup Change
    const onMarkupChange = (value) => {
        if (!value) {
            value = 0
        }
        const _margin = value / (1 + value / 100)
        setProductDetails((prevFormData) => ({
            ...prevFormData,
            markup: value,
            margin: Number(_margin.toFixed(2)),
        }))
        calculateSellingPrice(productDetails["purchaseCost"], _margin)
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                setLoading(true)
                queryProducts(request)
                    .then((resp) => {
                        callback(resp.body.data)
                        setLoading(false)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            }, 400),
        []
    )

    useEffect(() => {
        let active = true

        if (!inputValue) {
            setOptions([])
            return () => {
                active = false
            }
        } else {
            if (!selectedVendor) {
                enqueueSnackbar("Select Vendor first", {
                    variant: "warning",
                })
                return () => {
                    active = false
                }
            }
            fetch(
                {
                    companyId: selectedVendor.compId,
                    keyword: inputValue,
                },
                (results) => {
                    if (active) {
                        setOptions(results)
                    }
                }
            )
            return () => {
                active = false
            }
        }
    }, [inputValue, fetch, companyId, selectedVendor])

    const onSelectVendorProduct = (product) => {
        if (!product) {
            resetTemplate()
            return
        }
        product.sku &&
            getProductDetails({
                sku: product.sku,
                companyId: selectedVendor.compId,
            })
                .then((response) => {
                    console.log(response)
                    const _productDetail = response.body.data
                    setSelectedVendorProduct(_productDetail)
                    const _cost = _productDetail.pricing[0].sellingPrice
                    let _price = 0
                    let _margin = productDetails?.margin
                    let _markup

                    if (selectedVendor.isPlatformVendor) {
                        if (
                            _productDetail.inventory.suggestedSellingPrice &&
                            _productDetail.inventory.suggestedSellingPrice > 0
                        ) {
                            _price =
                                _productDetail.inventory.suggestedSellingPrice
                            _margin = ((_price - _cost) / _price) * 100
                            _margin = Number(_margin.toFixed(2))
                            _markup = Number(
                                (_margin / (1 - _margin / 100)).toFixed(2)
                            )
                        } else {
                            _price = _cost / (1 - _margin * 0.01)
                            _price = Number(_price.toFixed(5))
                        }
                    } else {
                        _price = _cost / (1 - _margin * 0.01)
                        _price = Number(_price.toFixed(5))
                    }

                    console.log(_margin, _cost, _price)
                    let _productDetails = productDetails
                    _productDetails["stockType"] = _productDetail.stockType
                    _productDetails["sku"] = _productDetail.sku
                    _productDetails["description"] = _productDetail.name
                    _productDetails["barcode"] = _productDetail.barcode
                    _productDetails["isDiscountableProduct"] =
                        _productDetail.isDiscountableProduct ? "true" : "false"
                    _productDetails["isTaxableProduct"] =
                        _productDetail.isTaxableProduct ? "true" : "false"
                    _productDetails["margin"] = _margin
                    _productDetails["markup"] = _markup
                    _productDetails["purchaseCost"] = _cost
                    _productDetails["sellingPrice"] = _price
                    setProductDetails(_productDetails)
                })
                .catch((error) => {
                    console.error(error)
                })
    }

    function onSubmit() {
        const payload = {
            companyId: companyId,
            companyName: companyName,
            userId: userId,
            stockType: productDetails.stockType,
            sku: productDetails.sku,
            description: productDetails.description,
            // purchaseCost: productDetails.purchaseCost,
            // sellingPrice: productDetails.sellingPrice,
            barcode: productDetails.barcode,
            // margin: productDetails.margin,
            isTaxableProduct:
                productDetails.isTaxableProduct === "true" ? true : false,
            isDiscountableProduct:
                productDetails.isDiscountableProduct === "true" ? true : false,
            isYardProduct:
                productDetails.isYardProduct === "true" ? true : false,
            isSuggestedPriceUpdate:
                productDetails.isSuggestedPriceUpdate === "true" ? true : false,
            // isFractionalProduct: productDetails.isFractionalProduct === "true" ? true : false,
            // allowFractional: productDetails.allowFractional === "true" ? true : false,
            autoSuggestedPo:
                productDetails.autoSuggestedPo === "true" ? true : false,
            autoPriceUpdate:
                productDetails.autoPriceUpdate === "true" ? true : false,
            categoryName: categoryType,
            inventory: {
                currentStock: productDetails.currentStock,
                purchasePackQuantity: productDetails.purchasePackQuantity,
                suggestedSellingPrice:
                    productDetails?.inventory?.suggestedSellingPrice,
            },
            // markup: productDetails.markup,
            catalogId: catalogId,
            pricing: [
                {
                    margin: productDetails.margin,
                    purchaseCost: productDetails.purchaseCost,
                    sellingPrice: productDetails.sellingPrice,
                    markup: productDetails.markup,
                },
            ],
            uomDetails: {
                uomCategory: selectedUOMCategory,
                inventoryUOM: selectedUOM,
                uom: [
                    {
                        name: selectedUOM,
                        enabled: true,
                        conversionFactor: 1,
                        uomCategory: selectedUOMCategory,
                        pricing: [],
                        default: true,
                    },
                ],
            },
        }
        setLoading(true)
        createLocalProduct(payload)
            .then((response) => {
                setLoading(false)
                if (!response.body.data) {
                    enqueueSnackbar("Product created successfully!", {
                        variant: "success",
                    })
                    if (selectedVendor?.compId && selectedVendorProduct?.sku) {
                        mapVendorProduct()
                    }
                    if (allowAddNewProduct) {
                        resetTemplate()
                    } else {
                        handleDialogClose()
                    }
                } else {
                    enqueueSnackbar(response.body.message, {
                        variant: "warning",
                    })
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    function mapVendorProduct() {
        setLoading(true)

        const uomTypes = selectedVendorProduct?.uomDetails?.uom || []
        const defaultUOM = uomTypes.find((o) => o.default)

        const requestBody = {
            vendorId: selectedVendor.compId,
            companyId: companyId,
            mapping: [
                {
                    deleted: false,
                    sku: productDetails.sku,
                    vendorSku: selectedVendorProduct.sku,
                    purchaseUOMList: [
                        {
                            name: defaultUOM?.name || "Each",
                            default: true,
                            uomCategory: defaultUOM?.uomCategory || "Count",
                            conversionFactor: defaultUOM?.conversionFactor || 1,
                            pricing: [
                                {
                                    fromQuantity:
                                        defaultUOM?.pricing.length > 0
                                            ? defaultUOM?.pricing[0]
                                                  .fromQuantity
                                            : 1,
                                    toQuantity:
                                        defaultUOM?.pricing.length > 0
                                            ? defaultUOM?.pricing[0].toQuantity
                                            : 9999999,
                                    price:
                                        defaultUOM?.pricing.length > 0
                                            ? defaultUOM?.pricing[0].price
                                            : selectedVendorProduct.pricing[0]
                                                  .sellingPrice,
                                },
                            ],
                        },
                    ],
                },
            ],
        }
        mapUnmapProducts(requestBody)
            .then((response) => {
                setLoading(false)
                if (response.body.data) {
                    handleDialogClose()
                    enqueueSnackbar("Product mapped with vendor sku", {
                        variant: "success",
                    })
                } else {
                    enqueueSnackbar("Mapping failed", {
                        variant: "warning",
                    })
                }
            })
            .catch((error) => {
                setLoading(false)
                if (error?.code === "ERR_BAD_REQUEST") {
                    enqueueSnackbar(
                        error.message || "mapping failed",
                        {
                            variant: "error",
                        }
                    )
                }
            })
    }

    useEffect(() => {
        resetTemplate()
    }, [selectedVendor])

    function resetTemplate() {
        setOptions([])
        setInputValue("")
        setSelectedVendorProduct({})
        defaultProductDetails.margin = inventoryMargin
        defaultProductDetails.markup = Number(
            (inventoryMargin / (1 - inventoryMargin / 100)).toFixed(2)
        )
        setProductDetails(defaultProductDetails)
        setSelectedUOMCategory("Count")
        const uomList =
            masterUOMCategories.find((cat) => cat.uomCategory === "Count")
                ?.uom || []
        setUOMUnitList(uomList)
        setSelectedUOM("Each")
        setCategoryType("")
    }

    function checkValidation() {
        // if (!productDetails.sku || !productDetails.description || !catalogId) {
        if (!productDetails.sku || !productDetails.description) {
            return true
        }
        return false
    }

    return (
        <Fragment>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <BootstrapDialog
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>Add Stock Product</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <HighlightOff />
                </IconButton>
                <DialogContent dividers>
                    <div className="content">
                        <div className="" style={{ marginBottom: "10px" }}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Create Using Vendor Product"
                                value={allowSearchForVendorProduct}
                                onChange={(e) => {
                                    setAllowSearchForVendorProduct(
                                        e.target.checked
                                    )
                                    if (e.target.checked === false) {
                                        resetTemplate()
                                    }
                                }}
                            />
                        </div>
                        {allowSearchForVendorProduct === true && (
                            <div className="vendorSection">
                                <Grid
                                    container
                                    rowSpacing={2}
                                    columnSpacing={2}
                                >
                                    <Grid item xs={6} sm={5} md={4} lg={5}>
                                        <VendorSelector
                                            companyId={companyId}
                                            selectedVendor={selectedVendor}
                                            setSelectedVendor={
                                                setSelectedVendor
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={5} md={4} lg={7}>
                                        <Autocomplete
                                            id="combo-box-demo1"
                                            loading={loading}
                                            options={options}
                                            filterOptions={(x) => x}
                                            getOptionLabel={(option) =>
                                                typeof option === "string"
                                                    ? option
                                                    : `${option.sku}  ${option?.name}`
                                            }
                                            className={""}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.sku === value.sku}
                                            inputValue={inputValue}
                                            onChange={(event, newValue) => {
                                                onSelectVendorProduct(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Search Vendor Product"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    size="small"
                                                    placeholder="Search"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {" "}
                                                                <Search />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(e) => {
                                                        setInputValue(
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <div className="">
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="SKU"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        name="sku"
                                        value={productDetails.sku}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={8}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Product Description"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        name="description"
                                        value={productDetails.description}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="UOM Category"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        select
                                        value={selectedUOMCategory}
                                        onChange={(e) =>
                                            handleChangeUOMCategory(
                                                e.target.value
                                            )
                                        }
                                    >
                                        {masterUOMCategories.map(
                                            (option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={option.uomCategory}
                                                >
                                                    {option.uomCategory}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="UOM"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        select
                                        value={selectedUOM}
                                        onChange={(e) =>
                                            setSelectedUOM(e.target.value)
                                        }
                                    >
                                        {uomUnitList.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option.name}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="Catalog"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        select
                                        onChange={(e) =>
                                            setCatalogId(e.target.value)
                                        }
                                    >
                                        {catalogList.map((option) => (
                                            <MenuItem
                                                key={option.catalogId}
                                                value={option.catalogId}
                                            >
                                                {option.catalogName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        label="Barcode"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        name="barcode"
                                        value={productDetails.barcode}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={8} md={8} lg={8}>
                                    <TextField
                                        fullWidth
                                        label="Category"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        select
                                        value={categoryType}
                                        onChange={(e) =>
                                            setCategoryType(e.target.value)
                                        }
                                    >
                                        {categoryList.map((option) => (
                                            <MenuItem
                                                key={option._id}
                                                value={option.categoryName}
                                            >
                                                {option.categoryName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{ mt: 1 }}
                                container
                                rowSpacing={2}
                                columnSpacing={2}
                            >
                                {booleanInputOptions.map((option, index) => (
                                    <>
                                        <BooleanInput
                                            {...option}
                                            index={index}
                                            value={
                                                productDetails?.inventory
                                                    ?.suggestedSellingPrice ??
                                                productDetails[option.field]
                                            }
                                        />
                                    </>
                                ))}
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={6} sm={5} md={4} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Cost per MBFT"
                                        value={allowCostPerMBFT}
                                        onChange={(e) =>
                                            setAllowCostPerMBFT(
                                                e.target.checked
                                            )
                                        }
                                    />
                                </Grid>
                                {allowCostPerMBFT && (
                                    <Fragment>
                                        <Grid item xs={10} sm={8} md={6} lg={5}>
                                            <Autocomplete
                                                sx={{ m: 1 }}
                                                id="combo-box-demo"
                                                options={uomProductList}
                                                getOptionLabel={(option) =>
                                                    option.itemName || ""
                                                }
                                                className={""}
                                                onChange={(event, newValue) => {
                                                    onChangeUOMProduct(newValue)
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="MBFT Product"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        size="small"
                                                        placeholder="Search"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    {" "}
                                                                    <Search />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <div {...props}>
                                                        {option.itemName}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Typography
                                                            align="right"
                                                            sx={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <b>Conversion:</b>{" "}
                                                            {
                                                                option.conversionFactor
                                                            }
                                                            &nbsp;&nbsp;
                                                            <b>Divide:</b>{" "}
                                                            {
                                                                option.devideFactor
                                                            }
                                                        </Typography>
                                                    </div>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={5} md={4} lg={4}>
                                            <PriceAndCostInput
                                                sx={{ m: 1, width: "25ch" }}
                                                label="Cost per MBFT"
                                                value={
                                                    productDetails.costMBFTPrice
                                                }
                                                onChange={onChangeCostPerMBFT}
                                            />
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <PriceAndCostInput
                                        sx={{ m: 1, width: "25ch" }}
                                        label="Cost per UOM"
                                        value={productDetails?.purchaseCost}
                                        onChange={onChangeCostPerUOM}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <MarginInput
                                        sx={{ m: 1, width: "25ch" }}
                                        label="Markup"
                                        value={productDetails?.markup}
                                        onChange={onMarkupChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <MarginInput
                                        sx={{ m: 1, width: "25ch" }}
                                        label="Margin"
                                        value={productDetails?.margin}
                                        onChange={onMarginChange}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <PriceAndCostInput
                                        sx={{ m: 1, width: "25ch" }}
                                        label="Selling Price"
                                        value={productDetails?.sellingPrice}
                                        onChange={onChangePrice}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={4}>
                                    <QuantityInput
                                        sx={{ m: 1, width: "25ch" }}
                                        label="Quantity"
                                        value={productDetails?.currentStock}
                                        onChange={(value) =>
                                            setProductDetails(
                                                (prevFormData) => ({
                                                    ...prevFormData,
                                                    currentStock: value,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        label="Add Another Product"
                        control={<Checkbox />}
                        value={allowAddNewProduct}
                        onChange={(e) =>
                            setAllowAddNewProduct(e.target.checked)
                        }
                    />
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onSubmit()}
                        disabled={checkValidation()}
                    >
                        Add Product
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}
