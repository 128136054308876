import { Auth } from "aws-amplify"

let sessionObject
export async function getAwsSession(params) {
    if (sessionObject) return sessionObject

    sessionObject = await Auth.currentSession()

    return sessionObject
}

export function clearAwsSession(params) {
    sessionObject = null
}
