import React, { createContext, useState } from "react"
import { getFiltersDropdownData } from "../pages/inventory/stock-products/Utils"

export const StockProductsFilters = createContext(null)

export default function StockProductsFiltersProvider({ children }) {
    const [selectedFilterValues, setSelectedFilterValues] = useState(
        getFiltersDropdownData().reduce((a, c) => {
            return { ...a, [c.filterApiKey]: [] }
        }, {})
    )

    return (
        <StockProductsFilters.Provider
            value={{ selectedFilterValues, setSelectedFilterValues }}
        >
            {children}
        </StockProductsFilters.Provider>
    )
}
