// PageNotFound.jsx
import React from "react"
import { Link } from "react-router-dom"
import "./pageNotFound.scss" // Create a CSS file for styling

const PageNotFound = () => {
    return (
        <div className="page-not-found">
            <div className="moon"></div>
            <div className="star star1"></div>
            <div className="star star2"></div>
            <div className="star star3"></div>
            <div className="message-container">
                <h1>404 - Page Not Found</h1>
                <p>Oops! It seems like you're lost in space.</p>
                <p>
                    Let's bring you back to{" "}
                    <Link to="/" className="home-link">
                        Earth
                    </Link>
                    .
                </p>
            </div>
        </div>
    )
}

export default PageNotFound
