import { RenderLink } from "../../../utils/utils"

export const chargesColumns = [
    {
        field: "sku",
        numeric: false,
        disablePadding: false,
        renderCell: ({ value, row }) => <RenderLink value={value} url={``} />,
        headerName: "Charge ID",
        headerClassName:
            "MuiDataGrid-pinnedColumnHeaders MuiDataGrid-pinnedColumnHeaders--left",
        cellClassName: "MuiDataGrid-pinnedColumns--left",
        width: 200,
    },
    {
        field: "name",
        numeric: false,
        disablePadding: false,
        headerName: "Description",
        headerClassName:
            "MuiDataGrid-pinnedColumnHeaders MuiDataGrid-pinnedColumnHeaders--left",
        cellClassName: "MuiDataGrid-pinnedColumns--left",
        width: 1000,
    },
    {
        field: "actions",
        disablePadding: false,
        headerName: "Actions",
        type: "actions",
        width: 300,
        headerClassName: "MuiDataGrid-pinnedColumnHeaders--right",
        cellClassName: "MuiDataGrid-pinnedColumns--right",
        filter: () => {},
    },
]

// export const chargesColumns = ({
//     setLoading,
//     toggleDeleteModal,
//     toggleDisconnectModal,
//     toggleConnectModal,
//     togglePrintModal,
//     vendorList = [],
//     catalogList = [],
// }) =>
//     [
//         {
//             field: "sku",
//             numeric: false,
//             disablePadding: false,
//             renderCell: ({ value, row }) => (
//                 <RenderLink
//                     value={value}
//                     url={``}
//                 />
//             ),
//             headerName: "Charge ID",
//             headerClassName:
//                 "MuiDataGrid-pinnedColumnHeaders MuiDataGrid-pinnedColumnHeaders--left",
//             cellClassName: "MuiDataGrid-pinnedColumns--left",
//             width: 200,
//         },
//         {
//             field: "name",
//             numeric: false,
//             disablePadding: false,
//             headerName: "Description",
//             headerClassName:
//                 "MuiDataGrid-pinnedColumnHeaders MuiDataGrid-pinnedColumnHeaders--left",
//             cellClassName: "MuiDataGrid-pinnedColumns--left",
//             width: 250,
//         },
//         {
//             field: "actions",
//             disablePadding: false,
//             type: "actions",
//             headerName: "Quick Actions",
//             getActions: (props) =>
//                 GetActions({
//                     setLoading,
//                     toggleDeleteModal,
//                     toggleDisconnectModal,
//                     toggleConnectModal,
//                     togglePrintModal,
//                     ...props,
//                 }),
//             width: 200,
//             headerClassName:
//                 "MuiDataGrid-pinnedColumnHeaders MuiDataGrid-pinnedColumnHeaders--right",
//             cellClassName: "MuiDataGrid-pinnedColumns--right",
//             filter: () => {},
//         },
//     ].map((item) => ({ ...item, filter: () => {} }))
