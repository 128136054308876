import { Delete, Edit, Message, Print } from "@mui/icons-material"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { Link, useNavigate } from "react-router-dom"

const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
})
export const usdPrice = {
    type: "number",
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: "font-tabular-nums",
}

export const RenderLink = ({ url, value }) => (
    <Link to={url}>
        <b>{value}</b>
    </Link>
)

export const GetActions = ({ id, row }) => {
    const navigate = useNavigate()
    return [
        <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={() =>
                navigate(
                    `/purchases/receive-ticket-edit?orderId=${row.deliveryReceiptId}`
                )
            }
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => id}
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Print />}
            label="Print"
            onClick={() => id}
            color="#9A9A9A"
        />,
        <GridActionsCellItem
            icon={<Message />}
            label="Message"
            onClick={() => id}
            color="#9A9A9A"
        />,
    ]
}
