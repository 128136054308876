import React from "react"
import { NumericFormat } from "react-number-format"
import { TextField } from "@mui/material"

export default function QuantityInput({
    onChange = () => {},
    value,
    ...props
}) {
    return (
        <NumericFormat
            type="text"
            variant="outlined"
            className={""}
            size="small"
            customInput={TextField}
            InputLabelProps={{ shrink: true }}
            value={value}
            onValueChange={(values) => {
                onChange(values.floatValue)
            }}
            onFocus={(e) => {
                e.target.select()
            }}
            allowLeadingZeros
            allowNegative={false}
            decimalScale={2}
            {...props}
        />
    )
}
