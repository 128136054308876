// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const CustomerReturnFiltersContext = createContext()

// Create the provider component
export default function CustomerReturnFiltersProvider({ children }) {
    const [filterCustomer, setFilterCustomer] = useState()
    const [filterProject, setFilterProject] = useState()
    const [filterReturnStatuses, setFilterReturnStatuses] = useState([])
    const [filterRefundStatuses, setFilterRefundStatuses] = useState([])
    const [filterSalespersons, setFilterSalespersons] = useState([])
    const [filterfromDate, setFilterFromDate] = useState(null)
    const [filtertoDate, setFilterToDate] = useState(null)

    const value = {
        filterCustomer,
        setFilterCustomer,
        filterProject,
        setFilterProject,
        filterReturnStatuses,
        setFilterReturnStatuses,
        filterRefundStatuses,
        setFilterRefundStatuses,
        filterSalespersons,
        setFilterSalespersons,
        filterfromDate,
        setFilterFromDate,
        filtertoDate,
        setFilterToDate,
    }

    return (
        <CustomerReturnFiltersContext.Provider value={value}>
            {children}
        </CustomerReturnFiltersContext.Provider>
    )
}
