import React from "react"
import { NumericFormat } from "react-number-format"
import { TextField, InputAdornment } from "@mui/material"

export default function PriceAndCostInput({
    onChange = () => {},
    value,
    className = "",
    decimalScale,
    ...props
}) {
    return (
        <NumericFormat
            type="text"
            variant="outlined"
            className={className}
            size="small"
            customInput={TextField}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                ),
            }}
            value={value}
            onValueChange={(values) => {
                onChange(values.floatValue)
            }}
            onFocus={(e) => {
                e.target.select()
            }}
            allowLeadingZeros
            allowNegative={false}
            decimalScale={decimalScale || 5}
            {...props}
        />
    )
}
