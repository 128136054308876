// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const POBillsFiltersContext = createContext()

// Create the provider component
export default function POBillsFiltersProvider({ children }) {
    const [filterVendor, setFilterVendor] = useState(null)
    const [filterReceiveStatuses, setFilterReceiveStatuses] = useState([])
    const [filterPayoutStatuses, setFilterPayoutStatuses] = useState([])
    const [filterSalespersons, setFilterSalespersons] = useState([])
    const [filterfromDate, setFilterFromDate] = useState(null)
    const [filtertoDate, setFilterToDate] = useState(null)
    const [filterfromAmount, setFilterFromAmount] = useState(null)
    const [filtertoAmount, setFilterToAmount] = useState(null)

    const value = {
        filterVendor,
        setFilterVendor,
        filterReceiveStatuses,
        setFilterReceiveStatuses,
        filterPayoutStatuses,
        setFilterPayoutStatuses,
        filterSalespersons,
        setFilterSalespersons,
        filterfromDate,
        setFilterFromDate,
        filtertoDate,
        setFilterToDate,
        filterfromAmount,
        setFilterFromAmount,
        filtertoAmount,
        setFilterToAmount,
    }

    return (
        <POBillsFiltersContext.Provider value={value}>
            {children}
        </POBillsFiltersContext.Provider>
    )
}
