import React from "react"
import { NumericFormat } from "react-number-format"
import { TextField, InputAdornment } from "@mui/material"

export default function MarginInput({
    limit = 100,
    onChange = () => {},
    value,
    ...props
}) {
    const checkLength = (inputObj) => {
        const { value, floatValue, formattedValue } = inputObj
        console.log(inputObj)
        if (value < limit || value === undefined) return true

        return false
    }

    return (
        <NumericFormat
            type="text"
            variant="outlined"
            className={""}
            size="small"
            customInput={TextField}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            value={value}
            isAllowed={checkLength}
            onValueChange={(values) => {
                onChange(values.floatValue)
            }}
            onFocus={(e) => {
                e.target.select()
            }}
            allowLeadingZeros
            allowNegative={true}
            decimalScale={2}
            {...props}
        />
    )
}
