import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import CloseIcon from "@mui/icons-material/Close"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

const MobileMenu = ({ mobileMenuLinks }) => {
    const [isClosing, setIsClosing] = useState(false)
    const [openSubmenuIndex, setOpenSubmenuIndex] = useState({})
    const location = useLocation()

    const handleDrawerToggle = () => {
        setIsClosing(!isClosing)
    }

    const handleDrawerClose = () => {
        setIsClosing(false)
    }

    const handleMobileMenuClose = () => {
        setIsClosing(false)
    }

    const handleToggleSubmenu = (index, level) => {
        setOpenSubmenuIndex((prev) => ({
            ...prev,
            [level]: prev[level] === index ? null : index,
        }))
    }

    const isActiveLink = (url) => {
        return location.pathname === url
    }

    const renderMenuItems = (items, level = 0) => {
        return items.map((item, index) => (
            <div key={item.name}>
                <ListItem disablePadding>
                    <ListItemButton
                        selected={openSubmenuIndex[level] === index}
                        onClick={() => handleToggleSubmenu(index, level)}
                        aria-expanded={
                            openSubmenuIndex[level] === index ? "true" : "false"
                        }
                        component={item.children ? "div" : "a"}
                        href={item.children ? null : item.url}
                        style={{
                            backgroundColor: isActiveLink(item.url)
                                ? "#f0f0f0"
                                : "inherit",
                            fontWeight: isActiveLink(item.url)
                                ? "bold"
                                : "normal",
                        }}
                    >
                        <ListItemText primary={item.name} />
                        {item.children &&
                            (openSubmenuIndex[level] === index ? (
                                <ExpandMoreIcon />
                            ) : (
                                <ChevronRightIcon />
                            ))}
                    </ListItemButton>
                </ListItem>
                {item.children && (
                    <Collapse
                        in={openSubmenuIndex[level] === index}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding sx={{ pl: 2 }}>
                            {renderMenuItems(item.children, level + 1)}
                        </List>
                    </Collapse>
                )}
            </div>
        ))
    }

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { lg: "none" } }}
                style={{ padding: "4px" }}
            >
                <MenuIcon style={{ fontSize: 24 }} />
            </IconButton>
            <Drawer
                open={isClosing}
                anchor={"right"}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box" } }}
            >
                <Box className="height-100p">
                    <Box height="420px" width="380px">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor="#ffffff"
                            padding={2}
                        >
                            <Typography variant="h6">Build Booster</Typography>
                            <Typography>
                                <CloseIcon
                                    onClick={handleMobileMenuClose}
                                    style={{ cursor: "pointer" }}
                                />
                            </Typography>
                        </Box>
                        <Divider />
                        <List>{renderMenuItems(mobileMenuLinks)}</List>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}

export default MobileMenu
