// FiltersContext.js
import React, { createContext, useState } from "react"

// Create the context with default values
export const PurchaseOrderFiltersContext = createContext()

// Create the provider component
export default function PurchaseOrderFiltersProvider({ children }) {
    const [filterVendor, setFilterVendor] = useState()
    const [filterStatus, setFilterStatus] = useState([])
    const [filterSalespersons, setFilterSalespersons] = useState([])
    const [filterfromDate, setFilterFromDate] = useState(null)
    const [filtertoDate, setFilterToDate] = useState(null)

    const value = {
        filterVendor,
        setFilterVendor,
        filterStatus,
        setFilterStatus,
        filterSalespersons,
        setFilterSalespersons,
        filterfromDate,
        setFilterFromDate,
        filtertoDate,
        setFilterToDate,
    }

    return (
        <PurchaseOrderFiltersContext.Provider value={value}>
            {children}
        </PurchaseOrderFiltersContext.Provider>
    )
}
