import { Auth } from "aws-amplify"
import axios from "axios"

const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://qa1-backend.buildbooster.com"
const DOWNLOAD_FILE = "DOWNLOAD_FILE/"

// Function to get the ID token from AWS Cognito
export const getIdToken = async () => {
    try {
        const session = await Auth.currentSession()

        return session.getIdToken().getJwtToken()
    } catch (error) {
        console.error("Error getting ID token:", error)
        throw error
    }
}

const createAxiosSetup = (noBase = false, customBaseUrl = BASE_URL) => {
    /*const HEADERS = {
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE"
    };*/

    const axiosInstance = axios.create({
        //headers: HEADERS,
        baseURL: noBase ? "" : customBaseUrl,
    })

    /**
     * Interceptor function to modify the request configuration before sending the request.
     * Adds JWT token to request headers for authentication and sets response type to "blob" for file downloads.
     * @param {Object} config - The request configuration object.
     * @returns {Object} - The modified request configuration.
     */
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
        async (config) => {
            config.headers["Content-Type"] = "application/json"
            config.headers["tenantId"] = localStorage.getItem("tenantId")
            // console.log({config});
            if (config.customHeaders) {
                config.headers = {
                    ...config.headers,
                    ...config.customHeaders,
                }
            }
            // Get the JWT token
            const token = await getIdToken()
            // console.log("token", token);

            // Add the token to the request header
            config.headers.Authorization = token

            // If the URL starts with "/downloadFile", set the responseType to "blob"
            // and remove the "/downloadFile" prefix from the URL.
            if (config.url.startsWith(DOWNLOAD_FILE)) {
                config.url = config.url.replace(DOWNLOAD_FILE, "")
                config.responseType = "blob"
            }

            return config
        },
        (error) => {
            if (error.response && error.response.data) {
                return Promise.reject(error.response.data)
            }
            return Promise.reject(error)
        }
    )
    const handleRequest = async (method, path, data, config = {}) => {
        try {
            const response = await axiosInstance[method](path, data, config)
            return { ...response, body: response.data }
        } catch (error) {
            throw handleError(error)
        }
    }

    const handleRequestForFile = async (method, path, data) => {
        return handleRequest(method, DOWNLOAD_FILE + path, data)
    }

    return {
        getRequest: (path, config = {}) =>
            handleRequest("get", path, undefined, config),
        postRequest: (path, data, config = {}) =>
            handleRequest("post", path, data, config),
        putRequest: (path, data, config = {}) =>
            handleRequest("put", path, data, config),
        delRequest: (path, config = {}) =>
            handleRequest("delete", path, undefined, config),
        // getRequest: (path) => handleRequest("get", path),
        // postRequest: (path, data) => handleRequest("post", path, data),
        // putRequest: (path, data) => handleRequest("put", path, data),
        // delRequest: (path) => handleRequest("delete", path),
        postFileRequest: (path, data) =>
            handleRequestForFile("post", path, data),
        getFileRequest: (path) => handleRequestForFile("get", path),
    }
}

export const handleError = (error) => {
    const axiosError = handleAxiosError(error)
    console.error(axiosError)
    return axiosError
}

const handleAxiosError = (error) => {
    const path = error?.config?.url
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { data, status, headers } = error.response
        // Extract relevant information from the headers if needed
        const contentType = headers["content-type"]

        const reqDetails = {
            ...data,
            request: JSON.parse(error?.config?.data || ""),
        }
        if (data.error) {
            return {
                path,
                code: data.error,
                status,
                message: data.message,
                contentType,
                reqDetails,
            }
        }
        // You can customize the error message based on the response data and status code
        const errorMessage = `Request failed with status code ${status}: ${JSON.stringify(
            data
        )}`

        return {
            path,
            code: error.code || "ERR_BAD_RESPONSE",
            status,
            message: errorMessage,
            contentType,
            reqDetails,
        }
    } else if (error.request) {
        // The request was made but no response was received
        return {
            path,
            code: error.code || "ERR_NO_RESPONSE",
            message: "No response received from the server.",
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        return {
            path,
            code: error.code || "ERR_REQUEST_SETUP",
            message: "Error in setting up the request.",
        }
    }
}

export default createAxiosSetup
