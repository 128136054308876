import { useRef } from "react"
import { TextField } from "@mui/material"

export default function ProductSearchBar({
    placeholder = "Search SKU/Description",
    debounceTimeout = 500,
    handleDebounce,
}) {
    const timerRef = useRef()

    const handleChange = (event) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }

        timerRef.current = window.setTimeout(() => {
            handleDebounce(event.target.value)
        }, debounceTimeout)
    }

    return (
        <TextField
            size="small"
            id="outlined-start-adornment"
            sx={{ width: { md: "23ch", lg: "30ch" } }}
            placeholder={placeholder}
            onChange={handleChange}
        />
    )
}
